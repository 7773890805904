.contact {
    height: 80vh;
    top: 50%;
}

input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    background-color: transparent;
    margin: 1rem 0;
    outline: none !important;
    border: 0.15rem solid #333333 ;
    padding: 1rem;
    color: #333333;
}

input[type=submit] {
    font-size: 1.5rem;
    transition: 0.15s ease-in;
}

input[type=submit]:hover {
    width: 100%;
    background-color: #333333;
    color: #fff;
    transition: 0.15s ease-in;
    margin: 1rem 0;
}

textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: 0.15rem solid #333333 ;
    outline: none !important;
    padding: 1rem;
    width: 100%;
    color: #333333;
    background-color: transparent;
    margin: 1rem 0;
    resize: none;
    height: 20vh;
}