.introduction {
    line-height: 4rem;
    font-size: 1.5rem;
    height: 40vh;
    /* display: block; */
    top: 50%;
}

.how-i-work {
    height: 40vh;
    line-height: 4rem;
    font-size: 2rem;
    top: 50%;
}

.work-item {
    border-bottom: 2px solid #b2ebf2;
    transition: 0.15s ease-in;
}
.work-item:hover {
    transition: 0.15s ease-in;
    border-bottom: 2px solid #00bcd4;
}
