.project-item {
    margin: 2rem 0;
    width: 100%;
    /* display: block; */
}
.project-item-title {
    font-size: 2rem;
    width: 100%;
}

.project-item-desc {
    font-size: 1.2rem;
    padding: 0.5rem;
    display: block;
}

.project-item-tech {
    font-size: 1rem;
    width: 100%;
    /* display: block; */
}

.project-item-tech-item {
    font-size: 0.8rem;
    padding: 0 1rem;
}

li {
    list-style: none;
}

.link-container {
    padding-right: 3rem;
}
.link {
    text-decoration: none;
    color: #333333;
    font-size: 1rem;
  }

.link:hover {
    color: rgba(51, 51, 51, 0.3);
    transition: 0.2s;
}
