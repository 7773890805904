.pnf {
    height: 80vh;
}
.pnf-code {
    font-size: 5rem;
    font-weight: 700;
}
.pnf-text {
    font-size: 2.5rem;
    font-weight: 300;
}

.pnf-back {
    text-decoration: none;
    color: #333333;
    font-size: 1.5rem;
    font-weight: 200;
}

.pnf-back:hover {
    color: rgba(51, 51, 51, 0.3);
    transition: 0.2s;
}

.thinking {
    width: 100%;
}