.page-title {
    font-size: 2rem;
    font-weight: 300;
    padding: 5rem 0rem;
    border-bottom: 0.1rem solid #333333;
    width: 100%;
}

.page-content {
    margin: 3rem 0;
    font-size: 1.2rem;
}

.about-me-desc {
    text-align: left;
}

.hi-image {
    width: 50%;
}

a {
    text-decoration: none;
    color: #333333;
    text-decoration: underline;
    /* font-size: 1rem; */
  }
  
  a:hover {
    color: rgba(51, 51, 51, 0.3);
    transition: 0.2s;
  }