.App {
  text-align: center;
  widows: 100%;
}
.nav-bar {
  height: 10vh;
  padding-top: 2rem;
  width: 100%;
  /* display: block; */
}
.app-content {
  width: 100%;
  display: block;
  padding-top: 1rem;
}
@font-face {
  font-family: 'aAutoSignature';
  src: url('./assets/fonts/aAutoSignature.ttf');
}

.nav-link {
  text-decoration: none;
  color: #333333;
  font-size: 1.5rem;
}

.nav-link:hover {
  color: rgba(51, 51, 51, 0.3);
  transition: 0.2s;
}

.my-name {
  font-family: 'aAutoSignature';
  font-size: 2rem;
}

.social {
  height: 5vh;
  width: 100%;
  /* display: block; */
  padding: 1rem;
}